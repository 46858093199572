import { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ portalId = 'portal', children }: PropsWithChildren<{ portalId?: string }>) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return mounted ? createPortal(children, document.getElementById(portalId) as HTMLElement) : null;
};

export default Portal;